import {Injectable, OnDestroy} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {HttpBackend, HttpClient} from "@angular/common/http";
import {BehaviorSubject, interval, Subject, Subscription} from "rxjs";
import {environment} from "../environments/environment";
import {Order, PollingResponse} from "./order";

@Injectable({
    providedIn: 'root'
})
export class OrderService implements  OnDestroy{
    order: Order={} as Order;
    orderId: string | undefined;
    rating: any;
    order_status: any;
    orderPayment: any;
    riderPosition = new BehaviorSubject({lat: 0, lng: 0})
    currencyCode: any;
    pickupToDropPathChangeCheck = 0;
    orderPollingSub: Subscription | undefined;
    pollingResponse: PollingResponse | undefined
    updatesSubject = new Subject() as Subject<PollingResponse>;

    constructor(private router: Router, private httpDirect: HttpClient,
                handler: HttpBackend,private activatedRoute: ActivatedRoute,) {
        this.httpDirect = new HttpClient(handler);
        this.activatedRoute.queryParams.subscribe(params=>{
            this.orderId=params['order_id'];
            if(!this.orderId){
                this.router.navigateByUrl('error').then();
            }else{
                this.orderPollingSub=interval(4000).subscribe(()=>{
                    if(!document.hasFocus()){
                        console.log('Browser tab is changed; skipping polling');
                        return;
                    }
                    this.fetchOrder().then();
                });
                this.fetchOrder().then();
            }
        })
    }


    async fetchOrder(){
        this.pollingResponse=await this.httpDirect.get<PollingResponse>(`${environment.apiUrl}order/order_tracking/${this.orderId}`).toPromise()
        console.log("polling response",this.pollingResponse);
        if(this.pollingResponse){
            this.order = this.pollingResponse?.data;
            this.rating=this.pollingResponse?.rating;
            this.updatesSubject.next(this.pollingResponse);
            if(this.pollingResponse.data.status_name==='delivered'||
            this.pollingResponse.data.status_name==='cancelled' ||
            this.pollingResponse.data.status_name==='reached_store'){
                console.log("call unsubscribe after");
                this.ngOnDestroy();
            }
        }
    }

    ngOnDestroy() {
        if (this.orderPollingSub) {
            this.orderPollingSub.unsubscribe();
        }
    }

    // async init() {
    //     const urlParams = new URLSearchParams(window.location.search);
    //     const myParam = urlParams.get('order_id');
    //     if (!myParam) {
    //         await this.router.navigateByUrl('error');
    //     }
    //     const api_url = environment.apiUrl;
    //     const response = await fetch(api_url + 'order/order_tracking/' + myParam, {
    //         method: "GET",
    //     });
    //     const data = await response.json()
    //     this.order = data.data;
    //     if (this.order.status_code >= 400 && this.pickupToDropPathChangeCheck !== 2) {
    //         this.pickupToDropPathChangeCheck = 1;
    //     }
    //     this.rating = data.rating
    // }

    async companyData() {
        const urlParams = new URLSearchParams(window.location.search);
        const myParam = urlParams.get('order_id');
        if (!myParam) {
            await this.router.navigateByUrl('error');
        }
        const api_url = environment.apiUrl;
        const company = await fetch(api_url + 'order/get_redis_company/' + myParam, {
            method: "GET",
        });
        const companyData = await company.json();
        this.currencyCode = companyData.data.currencyCode;
    }
}
