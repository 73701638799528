import { Component} from '@angular/core';
import {OrderService} from "../order.service";
import {Order} from "../order";
import {Router} from "@angular/router";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {interval, Subscription} from "rxjs";
import {environment} from "../../environments/environment";
@Component({
  selector: 'app-customer-tracking-page',
  templateUrl: './customer-tracking-page.component.html',
  styleUrls: ['./customer-tracking-page.component.css']
})
export class CustomerTrackingPageComponent  {

  order: Order = {
    id: '',
    rider: {
      name: ''
    },
    delivery_address: {
      google_address: ''
    }} as Order;
  feedbackorder = '';
  feedbackStatus: any;
  rating: any = {
  };
  rating3 = 0;
  public form: FormGroup;
  order_status: any;
  orderStatusDist = {
    50: null,
    200: null,
    300: null,
    400: null,
    500: null,
    600: null,
  };
  orderStatusDate = {
    50: '',
    200: '',
    300: '',
    400: '',
    500: '',
    600: '',
  };
  notes = 'Pickup in';
  orderSummaryValue: boolean = false;
  outletsContValue: boolean = false;
  order_Payment: any;
  body_temp: any;
  firstLocationTime: any;
  updatedTime: any = 0;
  currentUpdateTime: any;
  subTitleTime: any;
  infoModelValue: boolean = false;
  currentApplicationVersion = environment.appVersion;
  currencyCode: any;
  rider: any;
  color: any='#0db0a1';
  multiOrderCheck: boolean = false;
  outletCount=0
  deliveredAddress='';
  jmr=false;
  //companyProfile:string;
  subscriptions: Subscription[] = [];
  touch_point:any[]=[]
  settings: any;
  constructor(public orderService: OrderService, private router: Router, private fb: FormBuilder) {
    this.form = this.fb.group({
      rating1: ['', Validators.required],
      rating2: [4]
    });
    this.getdata().then()
    const sub = this.orderService.updatesSubject.subscribe(pollingResponse=>{
      this.receivedUpdate();
    });
    this.subscriptions.push(sub);
    if(this.orderService.pollingResponse?.data.id){
      this.receivedUpdate();
    }
  }

 async getdata(){
  const api_url = environment.apiUrl;
    const data = await fetch(api_url + 'auth/web/settings', {
      method: "GET",
  });
  this.settings = await data.json();
  console.log(this.settings?.logo);
  }

  async receivedUpdate(){
    this.touch_point=[];
    this.outletCount=0
    if(!this.orderService.pollingResponse?.data){
      return;
    }
    this.order=this.orderService.pollingResponse.data;
    if(this.order.company_id==='e12d333c-cb3f-49ba-b5a4-5490e1c322fe' || this.order.company_id==='5bbffa74-6008-491e-af12-621be8938405'){
      this.jmr=true
    }
    this.rider = Object.keys(this.order.rider).length;
    this.rating=this.orderService.pollingResponse.rating;
    this.order_status=this.orderService.pollingResponse.data.order_status;
    this.order_Payment = this.orderService.orderPayment;
    //this.companyProfile=this.order?
    if(!this.currencyCode) {
      await this.orderService.companyData().then();
    }
    this.currencyCode = this.orderService.currencyCode;
    this.order_status.forEach((row: any) => {
      // @ts-ignore
      this.orderStatusDist[row.status.code] = row.status.code;
      // @ts-ignore
      this.orderStatusDate[row.status.code] = row.created_on;
    })
    for (const [key, value] of Object.entries(this.order.touch_point)) {
      if(value?.order_type==='PICKUP'){
        this.touch_point.push(value);
        this.outletCount++;
      }
      if(value?.order_type==='DELIVERED'){
        this.deliveredAddress=value?.touch_point?.address+','+value?.touch_point?.pin_code;
      }
      
    }
    this.pollingData();
    this.numberMasking()
  }

  // async ngOnInit(): Promise<void> {
  //   await this.orderService.init().then();
  //   this.order = this.orderService.order;
  //   this.rating = this.orderService.rating;
  //   this.order_status = this.order.order_status;
  //   this.order_Payment = this.orderService.orderPayment;
  //   await this.orderService.companyData().then();
  //   this.currencyCode = this.orderService.currencyCode;
  //   this.order_status.forEach((row: any) => {
  //     // @ts-ignore
  //     this.orderStatusDist[row.status.code] = row.status.code;
  //     // @ts-ignore
  //     this.orderStatusDate[row.status.code] = row.created_on;
  //   })
  //   this.pollingData();
  //   this.numberMasking()
  // }

  // deg2rad1(deg: any) {
  //   return deg * (Math.PI / 180)
  // }
  pollingData(){
    const sub = interval(4000).subscribe(()=>{
      // this.orderService.init().then();
      this.order = this.orderService.order;
      this.order_status = this.order.order_status;
      this.order_status.forEach((row: any) => {
        // @ts-ignore
        this.orderStatusDist[row.status.code] = row.status.code;
        // @ts-ignore
        this.orderStatusDate[row.status.code] = row.created_on;
      });
      this.getTimeBtwTwoLatLng(this.order);
    });
    this.subscriptions.push(sub);
    if(this.order.status_name === 'delivered' || this.order.status_name === 'cancelled'){
      this.subscriptions.forEach(sub => {
        if (sub) {
          sub.unsubscribe();
        }
      });
    }
  }

  getTimeBtwTwoLatLng(order: any) {
    this.notes = order.status_code >= 400 ? 'Delivery in' : 'Pickup in';

    const { latitude: lat1, longitude: lng1 } = order.rider_position;

    let lat2 = order.pick_up_location?.latitude || 0;
    let lng2 = order.pick_up_location?.longitude || 0;

    if (this.multiOrderCheck) {
      const deliveryPoint = Object.values(this.order.touch_point).find(
        (value: any) => value?.order_type === 'DELIVERED'
      );
      if (deliveryPoint) {
        lat2 = order.status_code >= 400
          ? deliveryPoint.delivery_location?.latitude
          : order.pick_up_location?.latitude;
        lng2 = order.status_code >= 400
          ? deliveryPoint.delivery_location?.longitude
          : order.pick_up_location?.longitude;
      }
    } else {
      lat2 = order.status_code >= 400
        ? order.merchant_order?.delivery_location.latitude
        : order.pick_up_location?.latitude;
      lng2 = order.status_code >= 400
        ? order.merchant_order?.delivery_location.longitude
        : order.pick_up_location?.longitude;
    }

    const R = 6371;
    const dLat = this.deg2rad(lat2 - lat1);
    const dLon = this.deg2rad(lng2 - lng1);
    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const distance = R * 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    const AVERAGE_SPEED_KMH = 25;
    const timeInMinutes = Math.round((distance / AVERAGE_SPEED_KMH) * 60);

    this.updatedTime = Math.min(timeInMinutes, 120);
    this.subTitleTime = `${this.updatedTime} min`;

    this.firstLocationTime = Math.min(this.order.drop_off_eta / 60, 120);
    const progressPercentage = ((this.firstLocationTime - this.updatedTime) / this.firstLocationTime) * 100;
    this.currentUpdateTime = Math.max(0, Math.min(100, progressPercentage));
  }

  deg2rad(deg: any) {
    return deg * (Math.PI / 180)
  }


  feedback() {
    const api_url = environment.apiUrl;
    fetch(api_url + 'order/order_feedback/' + `${this.order.id}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        stars: this.rating3,
        feedback: this.feedbackorder,
        customer_id:this.order.merchant_order.customer_id,
        rider_id: this.order.rider_id,
      })
    }).then(async( res) => {
      const resData =  await res.json();
      this.feedbackStatus = resData.status;
      await this.orderService.fetchOrder();
      this.order = this.orderService.order;
      this.rating = this.orderService.rating;
    })
  }
  numberMasking(){
    // const date = this.order.created_on;
    // console.log('date ', moment(date).format('YYYY-MM-DD'));
    // const api_url = environment.apiUrl;
    // fetch(api_url + 'order/virtual_number', {
    //   method: 'POST',
    //   headers: {
    //     'Content-Type': 'application/json'
    //   },
    //   body: JSON.stringify({
    //     order_number: this.order.external_id,
    //     order_date: moment(date).format('YYYY-MM-DD'),
    //     location_code: "DPI66683",
    //     employee_code: this.order.rider_id,
    //   })
    // }).then(async( res) => {
    //    const riderData  =  await res.json();
    //    this.riderNumber = riderData.virtualNumber;
    //   // console.log('vvvvvvvvvvvvvvvvvv', this.riderNumber)
    // });
  }

  orderSummary() {
    this.orderSummaryValue = true;
  }

  orderSummaryValueClose() {
    this.orderSummaryValue = false;
  }
  outletsCont() {
    this.outletsContValue = true;
  }

  outletsContValueClose() {
    this.outletsContValue = false;
  }



  getRating(event: any, value: any) {
    this.rating3 = value;
  }

  riderPan() {
    this.orderService.riderPosition.next({lat:this.order.rider_position.latitude,
      lng: this.order.rider_position.longitude});
  }

  infoModel() {
    this.infoModelValue = true;
  }

  infoModelClose() {
    this.infoModelValue = false;
  }

}
