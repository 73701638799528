<!-- Header Start Here -->
<header>
    <div class="logo" *ngIf="!settings.logo">
      <img src="assets/images/rc_ic.svg" alt="">
      <h1>RoadCast</h1>
    </div>
    <div class="logo" *ngIf="settings.logo">
      <img [src]="settings.logo" alt="">
    </div>
  </header>
  
  
<!-- Header End Here -->

<!-- Google Map Start Here -->
<section class="gmap">
    <div class="powered"><img src="assets/images/rc_ic.svg" alt="map"></div>
<!--    <div class="recenter" (click)="riderPan()">-->
<!--        <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 121.64 122.86">-->
<!--            <title>direction-top</title>-->
<!--            <path d="M59,1.24.2,120.08a1.92,1.92,0,0,0,.88,2.58,1.89,1.89,0,0,0,1.76,0h0l58-30.87,58,30.87h0a1.89,-->
<!--            1.89,0,0,0,1.76,0,1.92,1.92,0,0,0,.88-2.58L62.64,1.24a2,2,0,0,0-3.64,0Z" fill="#0db0a1"/>-->
<!--        </svg>-->
<!--    </div>-->
    <app-test-map (multiOrderEmitter)="multiOrderCheck=$event"></app-test-map>
</section>
<!-- Google Map End Here -->

<!-- Order Container Start Here -->
<section class="ordercont">
    <div class="container">
        <div class="row">
            <div class="col">
                <div class="row orderstatus">
                    <div class="col-6"><h2>Order Id ({{order.touch_point?.external_id| uppercase}})</h2></div>
                    <div class="col-6 txt-right"><h2>#{{order.id.substr(-6, 6)| uppercase}}</h2></div>
                    <div class="w-100"></div>
                    <div class="col-12">
                        <div class="row">
                            <div class="col-7">
                                <ol class="timeline">
                                    <!--                                    <li [ngClass]="orderStatusDist['300'] === 300 ? 'active' : ''"><img src="assets/images/tick-w.png" alt="">Reach Pickup<span>at {{orderStatusDate["300"] | date: 'dd/MM/YYYY hh:mm a'}}</span></li>-->
                                    <li [ngClass]="orderStatusDist['400'] === 400 ? 'active' : ''">
                                        <img src="assets/images/tick-w.png" alt="">{{multiOrderCheck ? 'Dispatch' :
                                        'Dispatch'}}<span>at {{orderStatusDate["400"] | date: 'dd/MM/YYYY hh:mm a'}}</span></li>
                                    <li *ngIf="!multiOrderCheck" [ngClass]="orderStatusDist['500'] === 500 ? 'active' : ''">
                                        <img src="assets/images/tick-w.png" alt="">Reached Customer<span>
                                        at {{orderStatusDate["500"] | date: 'dd/MM/YYYY hh:mm a'}}</span></li>
                                    <li *ngIf="outletCount!=0">
                                        <img src="assets/images/tick-w.png" alt="">Outlets ({{this.outletCount}})
                                        <span style="color:#FF881B !important; cursor: pointer;" (click)="outletsCont()">View All Outlets</span>
                                    </li>
                                    <li [ngClass]="orderStatusDist['600'] === 600 ? 'active' : ''">
                                        <img src="assets/images/tick-w.png" alt="">Delivered<span>
                                        at {{orderStatusDate["600"] | date: 'dd/MM/YYYY hh:mm a'}}</span></li>
                                </ol>
                            </div>
                            <div class="col-5 txt-right">
                                <circle-progress
                                        [percent]="currentUpdateTime"
                                        [radius]="55"
                                        [titleFontSize]="'12'"
                                        [title]="notes"
                                        [subtitle]="subTitleTime"
                                        [maxPercent]="firstLocationTime"
                                        [innerStrokeColor]="color"
                                ></circle-progress>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 rider" *ngIf="order.status_code === 400 && jmr===false && multiOrderCheck==false">
                        Rider has picked up your order
                    </div>
                    <div class="col-12 rider"  *ngIf="order.status_code === 500 && jmr===false && multiOrderCheck==false">
                        Rider has reached your gate
                    </div>
                    <div class="col-12 rider"  *ngIf="order.status_code === 600 && jmr===false && multiOrderCheck==false">
                        Rider has delivered your order
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="jmr===false" class="row user">
            <div class="col">
                <div class="row">
                    <div class="col-6 u-name"><strong>{{multiOrderCheck ? order.rider.name : order.rider.name}}</strong>
                        <span>Your Rider</span>
                    </div>
                    <div  class="col-6  txtright">
                        <img src="assets/images/info_ic.svg" alt="" (click)="infoModel()" style="width:36px">
                        <a href="tel: {{order.rider ? order.rider.mobile_number : ''}}"
                           style="text-decoration: none">
                            <img src="assets/images/call_ic.svg" alt="" class="ml-6" style="width:36px"></a>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="jmr===true" class="row user">
            <div class="col">
                <div class="row">
                    <div class="col-6 u-name"><strong class="mb-0">{{multiOrderCheck ? order.rider.name  : order.rider.name}}</strong>
                        <span>Your Rider</span>
                    </div>
                    <div class="col-6 callbtn txtright">
                        <a href="tel: 9311331271"
                           style="text-decoration: none">
                            <img src="assets/images/icon-call-btn.svg" alt="" class="ml-6" style="width:36px">Call</a>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="jmr===true" class="row user">
            <div class="col">
                <div class="row">
                    <div class="col-12 add-cont">
                        <h2 class="add-head mb-1">Delivery Address</h2>
                        <p class="add">{{deliveredAddress ? deliveredAddress : ''}}</p>
                    </div>
                </div>
            </div>
        </div>


        <div class="row bottombtn">
            <div class="col-12">
                <button type="button" class="btn btn-primary w-100" (click)="orderSummary()">VIEW ORDER INFO</button>
            </div>
            <div class="col-12 poweredbot">
                Powered by RoadCast <span style="float: right">{{currentApplicationVersion}}</span>
            </div>
        </div>
        <!-- Scrollable modal -->
    </div>
</section>
<!-- Order Container End Here -->

<!-- Feedback Pop Up Start Here -->
<div *ngIf="order.status_name === 'delivered'">
    <div class="feedback-popup" *ngIf="!rating?.feedback">
        <div class="s-message">
            <img src="assets/images/successful.gif" alt="" style="width: 300px;">
            <div class="txts"><p class="success-text">Order Successfully Delivered</p>
                <p class="order-id">Order No:  <strong>#{{order.id.substr(-6, 6)  | uppercase}}</strong></p></div>

        </div>
        <div *ngIf="jmr===false" class="botcont">
            <div class="feedback-form">
                <div class="cont">
                    <div class="head">Your feedback is important to us!</div>
                    <div class="stars">
                        <div class="star-rating" (click)="getRating($event, '1')">
                            <img *ngIf="rating3 < 1" src="assets/images/grey_1.svg" alt="">
                            <img *ngIf="rating3 >=1" src='{{"assets/images/color_"+ rating3 + ".svg"}}' alt="">
                        </div>
                        <div class="star-rating" (click)="getRating($event,'2')">
                            <img *ngIf="rating3 < 2" src="assets/images/grey_2.svg" alt="">
                            <img *ngIf="rating3 >=2" src='{{"assets/images/color_"+ rating3 + ".svg"}}' alt="">
                        </div>
                        <div class="star-rating" (click)="getRating($event,'3')">
                            <img *ngIf="rating3 < 3" src="assets/images/grey_3.svg" alt="">
                            <img *ngIf="rating3 >=3" src='{{"assets/images/color_"+ rating3 + ".svg"}}' alt="">
                        </div>
                        <div class="star-rating"  (click)="getRating($event,'4')">
                            <img *ngIf="rating3 < 4" src="assets/images/grey_4.svg" alt="">
                            <img *ngIf="rating3 >= 4" src='{{"assets/images/color_"+ rating3 + ".svg"}}' alt="">
                        </div>
                        <div class="star-rating" (click)="getRating($event,'5')">
                            <img *ngIf="rating3 < 5" src="assets/images/grey_5.svg" alt="">
                            <img *ngIf="rating3 >= 5" src='{{"assets/images/color_"+ rating3 + ".svg"}}' alt="">
                        </div>
                    </div>
                </div>
                <div class="message">{{order.status_name | uppercase}}</div>
                <div>
                    <textarea [(ngModel)]="feedbackorder" class="form-control" rows="2" placeholder="What would you like to share..."></textarea>
                    <button type="button" class="btn btn-primary w-100" (click)="feedback()">SEND FEEDBACK</button>
                </div>
            </div>
        </div>
    </div>
</div>

<div  *ngIf="order.status_name === 'delivered'">
    <div class="feedback-popup">
        <div class="s-message"><img src="assets/images/successful.gif" alt="" style="width: 300px;"> </div>
        <div class="s-message1">
            Order No: <strong>#{{order.id.substr(-6, 6) | uppercase}}</strong><br><br>
            Order Id: <strong>#{{order.touch_point?.external_id| uppercase}}</strong>
        </div>

        <div class="botcont">
            <div  style="text-align: center;font-size: 14px;
           padding: 10px; position: relative; font-weight: bold"><p>Your order has been delivered successfully.</p></div>
        </div>
    </div>
</div>
<!-- Feedback Pop Up End Here-->

<!-- Order Cancelled -->
<div  *ngIf="order.status_name === 'cancelled'">
    <div class="feedback-popup">
        <div class="s-message">
            <div class="mb-3"><img src="assets/images/cancel.svg" alt="" style="width: 200px;"></div>
            <div class="txts">
             <p class="order-id mb-3">Order No: <strong>#{{order.id.substr(-6, 6) | uppercase}}</strong></p>
            <p style="color: red; font-weight: bold;">Your order has been Cancelled.</p></div>
        </div>
    </div>
</div>

<div *ngIf="!rider" style="text-align: center">
    <div class="feedback-popup">
        <div class="txts" style="margin-top: 40vh">
            <p class="order-id">Order No:  <strong>#{{order.external_id | uppercase}}</strong></p></div>
        <p style="color: black; font-weight: bold;">Waiting for Rider.</p>
    </div>
</div>


<!-- Outlet Container Start Here -->
<section class="order-summary-cont" *ngIf="outletsContValue && outletCount!=0">
    <div class="container">
        <div class="row">
            <div class="col-12 info">Outlets({{outletCount}})
                <div (click)="outletsContValueClose()" class="close-btn" style="cursor: pointer">x</div>
            </div>
            <section class="orderoutlt">
                <div class="container">
                    <div class="row">
                        <div class="col">
                            <div *ngFor="let touchPoint of touch_point; let i = index" class="row outletstatus">
                                <div class="col-6"><h2>#{{i+1}} {{touchPoint.touch_point?.name}}</h2></div>
                                <div class="col-6 txt-right"><p *ngIf="touchPoint.order_type==='PICKUP' && touchPoint.delivered!=null" class="green"><span>{{touchPoint.order_type}}, AT {{touchPoint.delivered | date:'short'}}</span></p><p *ngIf="touchPoint.order_type==='PICKUP' && touchPoint.delivered===null" class="orange"><span>Waiting for pickup</span></p></div>
                                <div class="col-8"><p class="address">{{touchPoint.touch_point?.address}},{{touchPoint.touch_point?.pin_code}}</p></div>
                                <hr>
                                
                            </div><!--
                            <div class="row outletstatus">
                                <div class="col-6"><h2>#2 Food Bus of India</h2></div>
                                <div class="col-6 txt-right"><p class="orange"><span>Waiting for pickup</span></p></div>
                                <div class="col-8"><p class="address">B - 66, Second Floor, Naraina Industrial Area Phase 2, New Delhi</p></div>
                                <hr>
                                <div class="row ordrqnty">
                                    <div class="col-6"><h2>Items</h2></div>
                                    <div class="col-6 txt-right"><h2>Quantity</h2></div>
                                    <div class="col-6 d-flex"><img src="assets/images/icon-non-veg.svg" alt=""><p class="ml-10">Kurkure Veg Momos</p></div>
                                    <div class="col-6 txt-right"><p>1</p></div>
                                </div>
                            </div>
                            <div class="row outletstatus">
                                <div class="col-6"><h2>#3 Kwality Wall's</h2></div>
                                <div class="col-6 txt-right"><p class="green"><span>Waiting for pickup</span></p></div>
                                <div class="col-8"><p class="address">B - 66, Second Floor, Naraina Industrial Area Phase 2, New Delhi</p></div>
                                <hr>
                                <div class="row ordrqnty">
                                    <div class="col-6"><h2>Items</h2></div>
                                    <div class="col-6 txt-right"><h2>Quantity</h2></div>
                                    <div class="col-6 d-flex"><img src="assets/images/icon-veg.svg" alt=""><p class="ml-10">Oreo Cornetto</p></div>
                                    <div class="col-6 txt-right"><p>1</p></div>
                                </div>
                            </div>-->
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</section>
<!-- Outlet Container End Here -->


<!-- Order Summary Start Here -->
<section class="order-summary-cont" *ngIf="orderSummaryValue">
    <div class="container">
        <div class="row">
            <div class="col-12 info">Order Info
                <div (click)="orderSummaryValueClose()" class="close-btn" style="cursor: pointer">x</div>
            </div>
            <div class="col-12">
                <div class="summary">
                    <div class="order-number">
                        <p class="head"><img src="assets/images/order_number.svg" alt=""> Order Number</p>
                        <p class="txt blue">{{order.id.substr(-6, 6) |uppercase}}</p>
                    </div>
                    <div class="order-Id">
                        <p class="head"><img src="assets/images/order_number.svg" alt=""> Order Id</p>
                        <p class="txt blue">{{order.external_id| uppercase}}</p>
                    </div>
                    <div *ngIf="!multiOrderCheck" class="address">
                        <p class="head"><img src="assets/images/location.svg" alt=""> Pickup Address</p>
                        <p class="txt">{{order.pick_up_address.google_address}}</p>
                    </div>
                    <div class="address">
                        <p class="head"><img src="assets/images/location.svg" alt=""> Delivery Address</p>
                        <p *ngIf="outletCount===0" class="txt">{{multiOrderCheck ? order.touch_point?.delivery_address?.address :
                            order.merchant_order?.delivery_address?.address}}</p>
                        <p *ngIf="outletCount!=0" class="txt">{{this.deliveredAddress ? this.deliveredAddress : ''}}</p>   
                    </div>
                    <div *ngIf="!multiOrderCheck" class="mode">
                        <p class="head"><img src="assets/images/payment.svg" alt=""> Payment Mode</p>
                        <p class="txt">Paid: <span  class="blue">{{order.payment_type}}</span></p>
                    </div>

                    <div class="date">
                        <p class="head"><img src="assets/images/date_time.svg" alt=""> Date</p>
                        <p class="txt">{{order.created_on | date :  'dd-MM-YYYY'}}</p>
                    </div>

                    <div class="items-details">
                        <table>
                            <tbody>
                            <tr>
                                <td *ngIf="!multiOrderCheck" colspan="2"><strong>Items</strong></td>
                                <td *ngIf="!multiOrderCheck" colspan="2" class="text-right"><strong>Amount</strong></td>
                            </tr>
                            <tr *ngFor="let item of order.order_items">
                                <td *ngIf="!multiOrderCheck" colspan="2">{{item.notes}} X{{item?.quantity}}</td>
                                <td colspan="2" class="text-right">{{item?.amount | currency : currencyCode}}</td>
                            </tr>
<!--                            <tr>-->
<!--                                <td colspan="2"><strong>Grand Total</strong></td>-->
<!--                                <td colspan="2" class="text-right"><strong>{{order.total | currency: currencyCode}}</strong></td>-->
<!--                            </tr>-->
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>



    <!-- order summary with outlets start here -->
    <section class="pb-4">
        <!--<div class="row orderinfo">
            <div class="col-12"><h2>Order Info</h2></div>
            <div class="col-6"><p><span>Order number</span></p></div>
            <div class="col-6 txt-right"><p class="orderid">#234d1</p></div>
            <div class="col-6"><p><span>Date & Time</span></p></div>
            <div class="col-6 txt-right"><p class="datetime">12-Aug-2022, at 12:23 PM</p></div>
        </div>
        <div class="row deliveryadd">
            <div class="col-12"><h2>Delivery Address</h2></div>
            <div class="col-10"><p><span>B - 66, Second Floor, Naraina Industrial AreaPhase 2, New Delhi, Delhi 110028</span></p></div>
        </div>-->

        <div *ngIf="outletCount!=0"  class="orderinfo-outlets">
            <h2>Outlets({{outletCount}})</h2>
        <section class="orderoutlt">
            <div class="container">
                <div class="row">
                    <div class="col">
                        <div *ngFor="let touchPoint of touch_point; let i = index" class="row outletstatus">
                            <div class="col-6"><h2>#{{i+1}} {{touchPoint.touch_point?.name}}</h2></div>
                            <div class="col-6 txt-right"><p *ngIf="touchPoint.order_type==='PICKUP' && touchPoint.delivered!=null" class="green"><span>{{touchPoint.order_type}}, AT {{touchPoint.delivered  | date:'short'}}</span></p><p *ngIf="touchPoint.order_type==='PICKUP' && touchPoint.delivered===null" class="orange"><span>Waiting for pickup</span></p></div>
                            <div class="col-8"><p class="address">{{touchPoint.touch_point?.address}},{{touchPoint.touch_point?.pin_code}}</p></div>
                            <hr>
                            <div class="row ordrqnty">
                                <div class="col-6"><h2>Items</h2></div>
                                <div class="col-6 txt-right"><h2>Quantity</h2></div>
                                <div class="row" *ngFor="let touch_pointpointItem of touchPoint.items">
                                    
                                    <div class="col-6 d-flex"><!--<img src="assets/images/icon-veg.svg" alt="">--><p class="ml-10">{{touch_pointpointItem.name}}</p></div>
                                    <div class="col-6 txt-right"><p>{{touch_pointpointItem.quantity}}</p></div>
                                </div>
                                
                            </div>
                        </div>
                        <!--<div class="row outletstatus">
                            <div class="col-6"><h2>#2 Food Bus of India</h2></div>
                            <div class="col-6 txt-right"><p class="orange"><span>Waiting for pickup</span></p></div>
                            <div class="col-8"><p class="address">B - 66, Second Floor, Naraina Industrial Area Phase 2, New Delhi</p></div>
                            <hr>
                            <div class="row ordrqnty">
                                <div class="col-6"><h2>Items</h2></div>
                                <div class="col-6 txt-right"><h2>Quantity</h2></div>
                                <div class="col-6 d-flex"><img src="assets/images/icon-non-veg.svg" alt=""><p class="ml-10">Kurkure Veg Momos</p></div>
                                <div class="col-6 txt-right"><p>1</p></div>
                            </div>
                        </div>
                        <div class="row outletstatus">
                            <div class="col-6"><h2>#3 Kwality Wall's</h2></div>
                            <div class="col-6 txt-right"><p class="green"><span>Waiting for pickup</span></p></div>
                            <div class="col-8"><p class="address">B - 66, Second Floor, Naraina Industrial Area Phase 2, New Delhi</p></div>
                            <hr>
                            <div class="row ordrqnty">
                                <div class="col-6"><h2>Items</h2></div>
                                <div class="col-6 txt-right"><h2>Quantity</h2></div>
                                <div class="col-6 d-flex"><img src="assets/images/icon-veg.svg" alt=""><p class="ml-10">Oreo Cornetto</p></div>
                                <div class="col-6 txt-right"><p>1</p></div>
                            </div>
                        </div>-->
                    </div>
                </div>
            </div>
        </section>
        </div>
    </section>
    <!-- order summary with outlets end here -->

</section>
<!-- Order Summary End Here -->

<!--info button page start here-->
<section class="info-window" *ngIf="infoModelValue">
    <div class="container info-popup">
        <div class="close-btn" (click)="infoModelClose()" style="top:10px; right: 30px;">x</div>

        <div class="row driverdet">
            <div class="col-12">
                <p><strong>Rider name: {{order?.rider?.name}}</strong><br>
                    <strong>Mobile number: {{order?.rider?.mobile_number}}</strong><br>
                    <strong>Vaccinated against Covid-19</strong> <span class="vc"><a href="/">View Certificate</a></span></p>
                <p class="bt">Body Temperature <span class="tempr">{{body_temp}}&#8457;</span></p>
            </div>
        </div>
    </div>
</section>
<!--info button page end here-->

<script src="https://cdn.jsdelivr.net/npm/@popperjs/core@2.9.2/dist/umd/popper.min.js" integrity="sha384-IQsoLXl5PILFhosVNubq5LC7Qb9DXgDA9i+tQ8Zj3iwWAwPtgFTxbJ8NT4GN1R8p" crossorigin="anonymous"></script>
<script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.min.js" integrity="sha384-cVKIPhGWiC2Al4u+LWgxfKTRIcfu0JTxR+EQDz/bgldoEyl4H0zUF0QKbrJ0EcQF" crossorigin="anonymous"></script>
